import { DateTime } from "luxon";

export type DateTimeLike = DateTime | number | Date | string;

export const toDateTime: (input: DateTimeLike) => DateTime = (i) => {
    if (i instanceof DateTime) return i;
    if (i instanceof Date) return DateTime.fromJSDate(i);

    switch (typeof i) {
        case "number":
            return DateTime.fromMillis(i);
        case "string":
            return DateTime.fromISO(i);
    }
};
