"use client";

import { DateTimeLike, toDateTime } from "@/functions/DateTimeLike";
import { Duration } from "luxon";
import { FC, useEffect, useMemo, useState } from "react";

type Props = {
    target: DateTimeLike;
};

export const Countdown: FC<Props> = ({ target }) => {
    const dtTarget = useMemo(() => toDateTime(target), [target]);

    const [diff, setDiff] = useState<Duration>(Duration.fromMillis(0));

    useEffect(() => {
        const id = setInterval(() => {
            let calcDiff = dtTarget.diffNow();
            setDiff(
                calcDiff.milliseconds < 0
                    ? Duration.fromMillis(-calcDiff.milliseconds).rescale()
                    : Duration.fromMillis(calcDiff.milliseconds).rescale()
            );
        }, 1000);

        return () => clearInterval(id);
    }, []);

    return (
        <span className="countdown font-mono text-2xl">
            <span style={{ "--value": diff.get("hour") } as any}></span>:
            <span style={{ "--value": diff.get("minute") } as any}></span>:
            <span style={{ "--value": diff.get("second") } as any}></span>
        </span>
    );
};
